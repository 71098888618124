<template>
  <div
    v-infinite-scroll="loadMoreActivities"
    infinite-scroll-disabled="infinityScrollBusy"
  >
    <v-card>
      <v-card-title class="mb-1">
        {{ $t('Activity') }}
      </v-card-title>
      <v-card-text>
        <div>
          <span class="font-weight-bold text-no-wrap me-2">{{ $t('Fullname') }}:</span>
          <span class="text--secondary">{{ userdata.full_name }}</span>
        </div>
        <div>
          <span class="font-weight-bold text-no-wrap me-2">{{ $t('PhoneNumber') }}:</span>
          <span class="text--secondary">{{ userdata.phone }}</span>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mt-4">
      <v-card-title>
        {{ $t('Timeline') }}
      </v-card-title>
      <v-card-text>
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <v-timeline
            dense
            class="timeline-custom-dense timeline-custom-dots"
          >
            <v-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              small
              :color="colors[index % colors.length]"
            >
              <div class="d-flex justify-space-between align-center flex-wrap">
                <h4 class="font-weight-medium me-1">
                  {{ $t(activity.type_activity) }}
                </h4>
                <small class="text-no-wrap">{{ timeFromNow(activity.created_at) }}</small>
              </div>
              <p class="mb-0">
                {{ $t(activity.type_activity) }} {{ $t('at') }} {{ formatTimestampToDatetime(activity.created_at) }}
              </p>
            </v-timeline-item>
          </v-timeline>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiFilePdf } from '@mdi/js'
import { ref } from '@vue/composition-api'
import store from '@/store'
import AccountService from '@/services/AccountService'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  setup() {
    const activities = ref([])
    const colors = ref(['info', 'warning', 'error', 'success'])
    const userdata = ref({ ...store.getters['auth/currentUser'] })
    const infinityScrollBusy = ref(false)
    const stopLoadMoreActivities = ref(false)
    const page = ref(1)
    const limit = 10

    const getData = async () => {
      infinityScrollBusy.value = true
      const resp = await AccountService.getListUserActivities({
        page: page.value,
        limit,
        id_user: userdata.value.id_user,
      })

      infinityScrollBusy.value = false

      const { data } = resp.data
      if (data.length === 0) {
        stopLoadMoreActivities.value = true
      } else {
        activities.value = [...activities.value, ...data]
        page.value += 1
        stopLoadMoreActivities.value = false
      }
    }

    const loadMoreActivities = async () => {
      if (!stopLoadMoreActivities.value) {
        await getData()
      }
    }

    return {
      userdata,
      activities,
      colors,
      icons: {
        mdiFilePdf,
      },
      loadMoreActivities,
      infinityScrollBusy,
    }
  },
  directives: { infiniteScroll },
}
</script>
